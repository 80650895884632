/* Color Theme Swatches in Hex */
.Nautical-Theme-1-1-hex { color: #2B2B2B; }
.Nautical-Theme-1-2-hex { color: #B9B9B9; }
.Nautical-Theme-1-3-hex { color: #18274B; }
.Nautical-Theme-1-4-hex { color: #FFFFFF; }
.Nautical-Theme-1-5-hex { color: #D4BB66; }

/* Color Theme Swatches in RGBA */
.Nautical-Theme-1-1-rgba { color: rgba(42, 42, 42, 1); }
.Nautical-Theme-1-2-rgba { color: rgba(184, 184, 184, 1); }
.Nautical-Theme-1-3-rgba { color: rgba(23, 38, 75, 1); }
.Nautical-Theme-1-4-rgba { color: rgba(255, 255, 255, 1); }
.Nautical-Theme-1-5-rgba { color: rgba(212, 186, 102, 1); }

html,
body {
    height: 100%;
    width: 100%;
}

body {
    font-family: sofia-pro,sans-serif;
    letter-spacing: .03em;
    line-height: 1.7em;
}

hr {
    border-color: $theme-primary;
    border-width: 1px;
    max-width: 75px;
}

hr.light {
    border-color: white;
}

a {
    @include transition-all;
    color: #fff;
    &:hover,
    &:focus {
        // color: darken($theme-primary, 10%);
        color: #fff;
    }
}

img {
    max-width: 100%;
}

h1.hero {
    font-size: 2.3rem;
    @media (min-width: 768px) {
        font-size: 3.7rem;
    }
}

h2.page-title {
    font-weight: 800;
    color: #fff;
    padding-top: 30px;
}

h3 {
    font-family: permanent-marker, sans-serif;
    font-size: 22px;
}

h4 {
    font-size: 14px;
    text-transform: uppercase ;
    line-height: 22px;
}

h4.tag-line {
    text-align: center;
    font-size: 12px;
    color: #B9B9B9;
}

h1,
h2,
h5 {
    font-family: sofia-pro,sans-serif;
    font-style: normal;
    color: $theme-primary;
    margin-bottom: 20px;
}

h6 {
    font-weight: bolder;
}

h6.page-title {
    font-family: sofia-pro, sans-serif;
    font-style: normal;
    font-size: 13px;
    color: $theme-secondary;
}

// .tag-line {
//     max-width: 400px;
//     line-height: 20px;
//     color: #101526;
// }

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #2B2B2B;
}

.bg-primary {
    background-color: $theme-primary;
}

.bg-dark {
    background-color: $theme-dark;
}

.text-faded {
    color: rgba(white, .7);
}

section {
    padding: 6rem 0;
}

.about {
    padding-top: 100px;
    padding-bottom: 50px;
}

.what-i-do {
    padding-top: 100px;
    padding-bottom: 100px;
    p {
        max-width: 700px;
    }
}

.what-i-offer {
    background-color: #f2f2f2;
    // color: #fff;
    padding-top: 110px;
    padding-bottom: 110px;
}

.what-i-solve {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, .9)),
    url('../img/header1.jpg');
    padding-top: 200px;
    padding-bottom: 200px;
    background-repeat: no-repeat;
    background-size: cover;
}

.service-banner {
    background-color: #18274B;
    border: solid 1px white;
    border-radius: 5px;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    h4 {
        font-size: 18px;
    }
    p {
        color: #fff;
    }
}

.section-post {
    color: #fff;
    background-size: cover;
    background-position: center;
  }

.heading {
    // padding: 100px 0 100px 0;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 3px black;
    background-image: url('../img/header.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    background-position: bottom;
}

.footer {
    background-color: $theme-primary;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 50px;
}


.copyright {
  background-color: $theme-primary;
  color: #fff;
}

aside {
    padding: 50px 0;
}

.no-padding {
    padding: 0;
}

// Navigation

.navbar-light .navbar-nav .nav-link {
  color: #f2f2f2;
  &:hover {
      color: #D4BB66;
  }
}

.navbar-default {
    background-color: transparent;
    border-color: rgba($theme-dark, .5);
    // @include sans-serif-font;
    @include transition-all;
    .navbar-header .navbar-brand {
        color: $theme-primary;
        // @include sans-serif-font;
        font-weight: 200;
        // text-transform: uppercase;
        &:hover,
        &:focus {
            color: darken($theme-primary, 10%);
        }
    }
    .nav {
        > li {
        text-align: center;
        margin-left: 20px;

            > a,
            > a:focus {
                // text-transform: uppercase;
                font-weight: 700;
                font-size: 13px;
                text-align: center;
                &:hover {
                    color: #fff;
                }
            }
            &.active {
                > a,
                > a:focus {
                    color: $theme-primary !important;
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        background-color: transparent;
        border-color: rgba(white, .3);
        .navbar-header .navbar-brand {
            color: rgba(white, .7);
            &:hover,
            &:focus {
                color: white;
            }
        }
        .nav > li > a {
          color: $theme-primary;
        }

        .nav > li > a:focus {
            color: rgba(white, .7);
            &:hover {
                color: white;
            }
        }
        &.affix {
            background-color: white;
            border-color: rgba($theme-dark, .5);
            .navbar-header .navbar-brand {
                color: $theme-primary;
                font-size: 14px;
                &:hover,
                &:focus {
                    color: darken($theme-primary, 10%);
                }
            }
            .nav > li > a,
            .nav > li > a:focus {
                color: $theme-dark;
                &:hover {
                    color: $theme-primary;
                }
            }
        }
    }
}

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

// Homepage Header

.image-header {
  background-image: url('../img/header.jpg');
}

.navbar-light .navbar-toggler {
  border-color: transparent;
}

.mask-post {
  background-image: linear-gradient(to top, rgba(0,0,0,.3), rgba(0,0,0,.8));
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 70px;
}

.page {
  position: relative;
  width: 100%;
  min-height: auto;
    // background-image: url('../img/header1.jpg');
  // @include background-cover;
  background-position: center;
  background-color: #f2f2f2;
  text-align: center;
  color: #fff;
  // padding-bottom: 20px;
  // padding-top: 20px;
}

.mask-page {
	background-color: #333;
  // background-image: linear-gradient(to bottom, rgba(0,0,0,.5), rgba(0,0,0,.2));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-bottom: 120px;
  @media (min-width: 768px) {
      // padding-bottom: 50px;
  }
}

.fa-bars {
  color: #fff;
}

.fa-times {
    color: red;
}

.post {
  position: relative;
  width: 100%;
  min-height: auto;
  // @include background-cover;
  background-position: center;
  background-image: url('../img/header1.jpg');
  text-align: center;
  color: #f2f2f2;
  // padding-bottom: 80px;
}

header {
    position: relative;
    width: 100%;
    min-height: auto;
    @include background-cover;
    background-position: center;
    background-image: url('../img/header4.jpg');
    text-align: left;
    color: #fff;
    .header-content {
        position: relative;
        text-align: left;
        padding: 150px 15px 150px;
        width: 100%;
        .header-content-inner {
            h1 {
                font-weight: 700;
                margin-top: 0;
                margin-bottom: 0;
                text-align: left;
                // font-size: 2.3rem;
                color: #fff;
            }

            hr {
                margin: 30px auto;
            }
            // p {
            //     font-weight: 300;
            //     color: rgba(white, .7);
            //     font-size: 16px;
            //     margin-bottom: 50px;
            // }
        }
    }
    @media (min-width: 768px) {
        min-height: 100%;
        .header-content {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 50px;
            .header-content-inner {
                max-width: 1000px;
                margin-left: auto;
                margin-right: auto;
                p {
                    font-size: 18px;
                    // max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

.header-mask {
  background-image: linear-gradient(to bottom, rgba(0,0,0,.9), rgba(0,0,0,.4));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-top: 20px;
  @media (min-width: 768px) {
      padding-bottom: 680px;
  }
  // padding-bottom: 120px;
}

// Sections

.section-heading {
    margin-top: 0;
}

.item-intro {
    font-size: 12px;
}

.service-box {
    max-width: 400px;
    margin: 50px auto 0;
    @media (min-width: 992px) {
        margin: 20px auto 0;
    }
    p {
        margin-bottom: 0;
    }
}
.call-to-action {
    h2 {
        margin: 0 auto 20px;
    }
}

.cta_block {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, .9)),
    url('../img/header.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

// Bootstrap Overrides
.text-primary {
    color: $theme-primary;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

// Button Styles
.btn-default {
    @include button-variant($theme-dark, white, white);
}

.btn-primary {
    @include button-variant(white, $theme-primary, $theme-primary);
}

.btn-secondary {
    font-family: sofia-pro;
    border: none;
    border-radius: .25rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #D4BB66;

    &:hover {
        color: #18274B;
        text-decoration: none;
        background-color: $theme-secondary;
    }
    box-shadow: 2px 2px #343a40;
}

.btn {
    font-family: sofia-pro;
    border: none;
    border-radius: .25rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #D4BB66;
    color: #fff;
    &:hover {
        color: #fff;
        background-color: $theme-primary;
    }
    box-shadow: 2px 2px #343a40;
}

.btn-xl {
    padding: 15px 30px;
}

.btn-xs {
    padding: 2px 5px;
}

.btn-show {
    color: #fff;
    background-color: #D4BB66;
    border-radius: 3px;
}

// Contact
#contact .fa {
    color: $theme-dark;
    font-size: 4em;
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

::selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

body {
    -webkit-tap-highlight-color: $theme-dark;
}

.history {
    background-color: #f2f2f2;
}

.video-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.video-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.video-portfolio {
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn-sm {
    margin-bottom: 5px;
}

.grid {
    height: 690px;
}

label {
    display: block;
}

.grid-item {
    // width: 100%;
    margin: 0;
    padding: 0;
}

// portfolio-box
.portfolio-item {
    // box-shadow: 0px 0px 200px 2px;
    border-radius: 10px;
    padding: 10px;
}

#portfolio .container-fluid, #portfolio .container-sm, #portfolio .container-md, #portfolio .container-lg, #portfolio .container-xl {
  max-width: 1920px;
}
#portfolio .container-fluid .portfolio-box, #portfolio .container-sm .portfolio-box, #portfolio .container-md .portfolio-box, #portfolio .container-lg .portfolio-box, #portfolio .container-xl .portfolio-box {
  position: relative;
  display: block;
}
#portfolio .container-fluid .portfolio-box .portfolio-box-caption, #portfolio .container-sm .portfolio-box .portfolio-box-caption, #portfolio .container-md .portfolio-box .portfolio-box-caption, #portfolio .container-lg .portfolio-box .portfolio-box-caption, #portfolio .container-xl .portfolio-box .portfolio-box-caption {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    opacity: 1;
    color: #fff;
  // background: linear-gradient(51, 51, 51, 1);
  // background: rgb(34,34,34, .3);
    background: linear-gradient(45deg, rgba(34,34,34,1) 0%, rgba(87,87,87,.4) 100%);
    transition: height 0.25s ease;
}

#portfolio .container-fluid .portfolio-box .portfolio-box-caption .project-category, #portfolio .container-sm .portfolio-box .portfolio-box-caption .project-category, #portfolio .container-md .portfolio-box .portfolio-box-caption .project-category, #portfolio .container-lg .portfolio-box .portfolio-box-caption .project-category, #portfolio .container-xl .portfolio-box .portfolio-box-caption .project-category {
    font-family: sofia-pro,sans-serif;
  font-size: 1rem;
  font-weight: 500;
  // text-transform: uppercase;
  // margin: 10px;
}
#portfolio .container-fluid .portfolio-box .portfolio-box-caption .project-name, #portfolio .container-sm .portfolio-box .portfolio-box-caption .project-name, #portfolio .container-md .portfolio-box .portfolio-box-caption .project-name, #portfolio .container-lg .portfolio-box .portfolio-box-caption .project-name, #portfolio .container-xl .portfolio-box .portfolio-box-caption .project-name {
    font-size: .7rem;
    @media (min-width: 768px) {
      font-size: 1rem;
  }
  line-height: normal;
}

#portfolio .container-fluid .portfolio-box:hover .portfolio-box-caption, #portfolio .container-sm .portfolio-box:hover .portfolio-box-caption, #portfolio .container-md .portfolio-box:hover .portfolio-box-caption, #portfolio .container-lg .portfolio-box:hover .portfolio-box-caption, #portfolio .container-xl .portfolio-box:hover .portfolio-box-caption {
  opacity: .9;
}

.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 ratio */
	height: 0;
	overflow: hidden;
	max-width: 100%;
	background: #000;
	margin-top: 20px;
}
.video-container iframe,
.video-container object,
.video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}